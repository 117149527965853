/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        AOS.init({anchorPlacement: 'top-bottom', delay: 200,  offset: 200});

        $(window).on('load', function () {
        AOS.refresh();
        });

        function hamburgerClose(el){
          el.removeClass('hamburger-active');
          $('html').removeClass( 'hamburger-active' );
          $('#mobile-nav-menu').removeClass('active');
        }

        function hamburgerOpen(el){
          el.addClass('hamburger-active');
          $('html').addClass( 'hamburger-active' );
          $('#mobile-nav-menu').addClass('active');
        }

        $('.js-navigation-toggle').click(function(e) {
          e.preventDefault();
          if($(this).hasClass('hamburger-active') ){
            hamburgerClose($(this)); 
          }else{
            hamburgerOpen($(this));
          }
        });

        $('#cover').click(function() {
          $('.js-navigation-toggle').click();
        });

        /*
        var navbar = document.getElementById("navbar");
        
        function headerTrans(){
          navbar.classList.add("dark");
        }

        function headerUnTrans(){
          navbar.classList.remove("dark");
        }

        
        function myFunction() {
          var sticky = document.documentElement.scrollTop;
          var int;

          if (sticky !== 0) {
            navbar.classList.add("sticky");
            window.clearTimeout(int);
            int = setTimeout(headerTrans, 500);
          } else {
            window.clearTimeout(int);
            navbar.classList.remove("sticky");
            int = setTimeout(headerUnTrans, 500);
          }
        }

        window.onscroll = function() {
          myFunction();
        };
        myFunction();
        */

        $('.leadership_grid .column').mouseenter(function(e) {
          $(this).find('.contain').slideDown( "fast", function() {
            $(this).find('.about').addClass('on');
          });
        });

        $('.leadership_grid .column').mouseleave(function(e) {
          $(this).find('.contain').slideUp( "fast", function() {
            $(this).find('.about').removeClass('on');
          });
        });

        if( $('.filters').length ){
          val = $('.filter.selected').attr('data-val');

          if($('.block_capabilities').length){
            if($('.block_capabilities').attr('filter-pref')!=""){
              val = $('.block_capabilities').attr('filter-pref');
              $('.filter').removeClass('selected');
              $('.filter[data-val='+val+']').addClass('selected');
            }
          }


          //console.log(val);
          $('.point').hide();
          $('.point.'+val).show();

          $('.capabilities .filter').click(function(e){
            e.preventDefault();
            val = $(this).attr('data-val');
            $('.filter').removeClass('selected');
            $(this).addClass('selected');
            $('.point').hide();
            $('.point.'+val).show();
            history.replaceState({page: 1}, "Capabilities - Procon Mining &amp; Tunnelling", "/capabilities/"+val+"/");
          });

          $('.capacites .filter').click(function(e){
            e.preventDefault();
            val = $(this).attr('data-val');
            $('.filter').removeClass('selected');
            $(this).addClass('selected');
            $('.point').hide();
            $('.point.'+val).show();
            history.replaceState({page: 1}, "Capabilities - Procon Mining &amp; Tunnelling", "/fr/capacites/"+val+"/");
          });

          $('.projects .filter').click(function(e){
            e.preventDefault();
            val = $(this).attr('data-val');
            $('.filter').removeClass('selected');
            $(this).addClass('selected');
            $('.point').hide();
            $('.point.'+val).show();
          });

          if($('.block_projects').length){
            if(window.location.hash) {
              hash = window.location.hash.substring(1);
              console.log(hash);
              $('.projects .filter[data-val='+hash+']').click();
            } 
          }
        }

        if( $('body.single-capability').length ){
          $('.menu-item-35').addClass('current_page_item ');
        }

        if( $('body.single-project').length ){
          $('.menu-item-36').addClass('current_page_item ');
        }
        

        var swiper = new Swiper('#slider', {
          slidesPerView: 1,
          spaceBetween: 0,
          loop: true,
          touchEventsTarget: 'wrapper',
          // init: false,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          }
        });

        var swiper2 = new Swiper('#career_slider', {
          slidesPerView: 1,
          spaceBetween: 0,
          loop: true,
          touchEventsTarget: 'wrapper',
          // init: false,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          breakpoints: {
            711: {
              slidesPerView: 3,
              spaceBetween: 0,
            }
          }
        });

        $('.nav-primary li a').each(function() {
          $( this ).attr('data-text',$(this).html().replace(/(<([^>]+)>)/ig,""));
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
